import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"

import { CheckList, Footer, Header } from "../../components"
import { ImageCircle } from "../../components/image-circle"
import { JuryNote } from "../../components/jury-note"
import { Quote } from "../../elements"
import ReviewFlow from "../../elements/review-flow"
import { BodyText, Display, Highlight, Lead, Title } from "../../identity"
import { Blue } from "../../identity/typography/blue"
import { List } from "../../identity/typography/list"
import { Container } from "../../layout"
import { theme } from "../../theme"
import { Link } from "../../identity/typography/link"

export default () => {
  const {
    snack: {
      childImageSharp: { fixed: snack },
    },
    reviewFlow: {
      childImageSharp: { fluid: reviewFlow },
    },
    bike: {
      childImageSharp: { fixed: bike },
    },
    discussing: {
      childImageSharp: { fixed: discussing },
    },
    recommender: {
      childImageSharp: { fluid: recommender },
    },
    stairs: {
      childImageSharp: { fixed: stairs },
    },
    philipp: {
      childImageSharp: { fixed: philipp },
    },
    logoCloud: {
      childImageSharp: { fluid: logoCloud },
    },
  } = useStaticQuery(graphql`
    query {
      snack: file(relativePath: { eq: "migipedia/02_snack_001.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      discussing: file(
        relativePath: { eq: "migipedia/01_diskutieren_007.jpg" }
      ) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recommender: file(
        relativePath: { eq: "migipedia/phone-landingpage-top.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviewFlow: file(relativePath: { eq: "migipedia/review-flow.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bike: file(relativePath: { eq: "migipedia/03_bike_016.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      couch: file(relativePath: { eq: "migipedia/05_couch_010.jpg" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stairs: file(relativePath: { eq: "migipedia/04_kitchen_012.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      philipp: file(relativePath: { eq: "migipedia/philipp-buehler.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCloud: file(relativePath: { eq: "migipedia/logo-cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>smartive — Eingaben Best of Swiss Web 2021</title>
        </Helmet>
        <Normalize />
        <Header
          heading="Eingabe Best of Swiss Web 2021"
          subheading="Kategorie Usability"
        />

        <Container>
          <Display>
            <Highlight>MIGIPEDIA</Highlight> – 10 Jahre User im Mittelpunkt
          </Display>
          <Lead>
            Seit 2010 stehen bei Migipedia – der Community der Migros –
            Kundinnen und Kunden im Mittelpunkt. Die Ansprüche der User an die
            Plattform haben sich in dieser Zeit verständlicherweise immer wieder
            verändert. Mit dem Migipedia-Relaunch von 2020 wird diesen
            Veränderungen einmal mehr Rechnung getragen. Mit Erfolg: Die
            Community ist heute so lebendig wie nie zuvor.
          </Lead>
          <Lead>
            <Link href="https://migipedia.migros.ch" target="_blank">
              Migipedia.ch
            </Link>{" "}
            macht es Kundinnen und Kunden leicht, sich miteinander auszutauschen
            und mit der Migros in Kontakt zu treten. Die primäre Zielgruppe sind
            junge Familien und Digital Natives. Diese möchten ein ansprechendes,
            schnelles und unkompliziertes Nutzererlebnis, wie sie es von Apps
            kennen.
          </Lead>
        </Container>

        <ImageCircle
          images={{
            left: stairs,
            right: snack,
            center: recommender,
          }}
        />

        <Container>
          <BodyText>
            <CheckList
              items={[
                "80% mobile Traffic: Relaunch von Migipedia mit Fokus auf mobile.",
                "Reduktion auf 6 aktiv formulierte Navigationspunkte.",
                "60% mehr Bewertungen im Vergleich zum Vorjahr.",
                "Halbiert: die durchschnittliche Zeit, die ein Kunde für eine Bewertung aufbringen muss.",
                "Personalisierte Empfehlungen als Alternative zur Suche",
              ]}
            />
          </BodyText>
        </Container>

        <Container>
          <Title number="01">
            App? <Highlight>Web</Highlight>
            <Blue>!</Blue>
          </Title>
          <BodyText>
            Beinah 80% der Nutzerinnen und Nutzer besuchen Migipedia mit einem
            Smartphone. Eine{" "}
            <Link
              href="https://www.emarketer.com/content/the-majority-of-americans-mobile-time-spent-takes-place-in-apps"
              target="_blank"
            >
              aktuelle Studie
            </Link>{" "}
            geht davon aus, dass User während der Nutzung eines mobile Gerätes
            zu knapp 90% in Apps befindet, wohingegen nur etwa 10% der Zeit für
            das Browsen im Internet verwendet wird. Was, wenn Migipedia eine App
            wäre?
          </BodyText>
          <BodyText>
            Daran orientiert sich die Überarbeitung der Plattform. Und zwar
            nicht nur, indem der Content verkleinert dargestellt wird, sondern
            mit Augenmerk auf die Benutzbarkeit. So bietet Migipedia
            beispielsweise grosse Touchflächen, ideal für Smartphones. Aber ist
            es möglich, noch einen Schritt weiter zu gehen und Migipedia zu
            einer App im Web zu machen?
          </BodyText>
          <BodyText>
            Dafür kommen UX-Konzepte zum Einsatz, die aus Apps bekannt sind:
            Slider sparen Platz in der Horizontale und lassen sich seitlich
            wischen, um mehr ähnliche Inhalte zu entdecken. Durch Client-Side
            Transitions mit Prefetching sind Seitenwechsel keine Angelegenheit
            mit ungewisser Wartezeit mehr, sondern fast unmittelbar.
          </BodyText>
          <BodyText>
            Einen schnellen Wechsel zu allen Bereichen gewährleistet auch die
            simple Navigationsstruktur. Alle Hauptfunktionen sind prominent
            aufgeführt und mit einem Tippen erreichbar: bewerten, fragen,
            testen, abstimmen, diskutieren, tauschen.
          </BodyText>
          <BodyText>
            Nicht nur beim Aufbau, sondern auch an diversen weiteren Ecken wurde
            auf eine Bedienung und Benutzerführung geachtet, die prägnant und
            zuvorkommend ist:
          </BodyText>
          <List listType="unordered">
            <BodyText>
              Auf der Startseite werden wiederkehrende User in einem
              persönlichen Einstiegsbereich begrüsst.
            </BodyText>
            <BodyText>
              Für einen barrierefreien Zugang spricht der
              Lighthouse-Accessibility-Score von 97%. Accessibility-Reviews
              geben Hinweise für weitere Verbesserungen.
            </BodyText>
            <BodyText>
              Micro-Interactions tragen dem Umstand Rechnung, dass User am
              Smartphone kürzer angebunden sind. Die Profil-Einstellungen
              bestehen z. B. aus mehreren Toggles für unterschiedliche Arten von
              Mitteilungen – tipp, Benachrichtigungen an, tapp, Newsletter
              abonniert.
            </BodyText>
          </List>
        </Container>

        <Container>
          <Title number="02">
            <Highlight>Heureka</Highlight> statt Heuhaufen
          </Title>
          <BodyText>
            Hier für dich: alle Migros-Produkte. Und alle Meinungen, alle
            Fragen, alle Diskussionen dazu. Wenn du jetzt ein bisschen
            überfordert bist: Hier noch drei ausgewählte Produkte, die dich
            vielleicht interessieren. Oder willst du eine Meinung loswerden zum
            Knuspermüesli, das bei deinem letzten Einkauf im Korb war?
          </BodyText>
          <ReviewFlow />
          <BodyText>
            Mehr ist nicht immer besser. Wer will schon unter Tausenden
            Produkten nach dem Produkt suchen, das man neulich gekauft hatte?
            (Waren das jetzt Blaubeeren oder Waldbeeren?) – Hier schaffen die
            neuen Recommender Abhilfe. Wer das Cumulus-Konto mit seinem
            Migros-Login verknüpft hat, profitiert von personalisierten
            Empfehlungen, die so manche Suche überflüssig machen. So muss man
            gar nicht lang überlegen, welches Müesli das genau war, von dem man
            wissen wollte, ob dort Nüsse drin sind.
          </BodyText>
          <BodyText>
            Auch wer ohne Müesli im Hinterkopf ankommt, muss nicht lang nach
            einer Idee suchen: Persönliche Empfehlungen und Big Data machen auch
            Stöbern zum Vergnügen. Die eigenen Interessen werden berücksichtigt,
            sodass Sparfüchse und Bio-Tiger gleichsam auf ihre Kosten kommen.
          </BodyText>
        </Container>

        <Quote
          author="Philipp Bühler, Senior Product Owner Migipedia & Brand"
          photo={philipp}
        >
          &laquo;Wenn Migipedia eine App wäre … bei knapp 80% Mobile Use der
          Community haben wir uns nicht an Web-, sondern an App-Pattern
          orientiert. Zu Recht, wie die Community-Aktivitäten zeigen.&raquo;
        </Quote>

        <Container>
          <Title number="03">Lädt bevor dus merkst</Title>
          <BodyText>
            Usability beginnt bei der Performance. Erst wenn die Seite
            übertragen und geparst ist, erscheint etwas auf dem Bildschirm. Und
            erst wenn das nötige JavaScript ausgeführt ist, sind alle
            Interaktionen möglich.
          </BodyText>
          <BodyText>
            Um die Ladezeiten so gering wie möglich zu halten, nutzt Migipedia
            diverse Kniffe: Da wäre einmal der Tech-Stack, der das Ausliefern
            vorab generierter Seiten ermöglicht; dann das Übermitteln von
            Client-Code in Teilen, damit das initiale Rendern nicht blockiert
            wird; dazu passende Bildformate und -grössen für verschiedene
            Geräte; ausserdem Caching in der API und schliesslich Monitoring
            mittels Lighthouse Score und Newrelic.
          </BodyText>
          <BodyText>
            Beim Klick auf einen Link wird nicht alles abgeräumt zugunsten einer
            weissen Fläche und eines Ladebalkens, sondern eine Client-Side
            Transition wird angestossen: Dank Prefetching sind die Eckdaten des
            neuen Inhalts bereits im Client vorhanden. Sie werden in die
            bestehende Seite eingefügt, ohne dass diverse Skripte und das
            Rendering von Grund auf neu ablaufen müssen.
          </BodyText>
        </Container>

        <ImageCircle
          images={{
            left: discussing,
            right: bike,
            center: reviewFlow,
          }}
        />

        <Container>
          <Title number="04">
            Von Migipedia zur <Highlight>Migros-Community </Highlight>
          </Title>
          <BodyText>
            Migipedia fügt sich nun noch besser ins Migros-Universum ein. Sieht
            aus wie Migros, fühlt sich an wie Migros. Der Wiedererkennungseffekt
            ist gewährleistet. Prominenter Teil des neuen Erscheinungsbilds ist
            zum Beispiel der Spickel, der dem Migros-M entstammt. Aber auch in
            Sachen UX wurde darauf geachtet, dieselbe Sprache zu sprechen. Auf
            jeder Seite gibt es beispielsweise einen klaren Call to Action.
          </BodyText>
          <BodyText>
            Auf mehreren Plattformen eingesetzte Widgets bieten überall die
            gleiche Experience: Das Benutzer-Menu und Produktekacheln dürften
            aus der Migros-Welt bekannt sein, umgekehrt sind die
            Community-Widgets auch anderswo im Einsatz. Widgets stellen
            Bewertungs- und Fragefunktionen weiteren Migros-Plattformen zur
            Verfügung, mit flexiblen Theming-Anpassungen.
          </BodyText>
        </Container>

        <Container>
          <GatsbyImage fluid={logoCloud} />
        </Container>

        <Container>
          <Title number="05">
            Von der Plattform hin zum <Highlight>Community-Service </Highlight>
          </Title>
          <BodyText>
            Ein Rezept auf Migusto bewerten, eine Produktfrage in der Migros App
            stellen oder beantworten, einen Einkauf auf SportXX bewerten, an
            einem Produkttest auf Migros.ch teilnehmen oder im Forum auf
            Migipedia mitdiskutieren – nach dem Motto «fischen, wo die Fische
            sind und nicht dort, wo die Migros den schönsten Angelplatz
            vermutet», finden sich heute Migipedia Community Features auf elf
            unterschiedlichen digitalen Touchpoints der Migros. Den Erfolg
            dieser Modularisierung lässt sich messen: Mit jeder Plattform, die
            zusätzlich mit Community-Features erschlossen werde konnte, wurden
            die User-Interaktionen im Durchschnitt um 12% erhöht. Im Schnitt
            wird aktuell alle 2.5 Minuten ein Produkt bewertet. Am fleissigsten
            war die Community am 23. Mai 2020 - beinahe im Minutentakt
            interagierten User an diesem Tag dank modularisierten
            Community-Features über 11 digitale Touchpoints hinweg mit der
            Migros.
          </BodyText>
        </Container>

        <JuryNote />
        <Footer />
      </>
    </ThemeProvider>
  )
}
